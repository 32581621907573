import React from 'react';
import './css/index.scss';
import { useSelector } from 'react-redux';
import SearchInput from '../SearchInput';
import TableHeader from '../TableHeader';
import TablePagination from '../TablePagination';
import useComponentVisible from '../../helpers/hooks/useComponentVisible';

function MyTable({
  perPage,
  setPerPage,
  filters = [],
  selectedFilter,
  setSelectedFilter,
  q,
  setQ,
  headerColumns,
  Item,
  data,
  page,
  setPage,
  total,
  isSecondaryStyle,
}) {
  const { ref, isComponentVisible, setIsComponentVisible } = useComponentVisible(false);

  const handlePerPage = (val) => () => {
    setPerPage(val);
    setIsComponentVisible(false);
  };

  return (
    <div className="table_wrapper">
      <div className="table_top_row">
        {!isSecondaryStyle && (
        <div className="per_page">
          <p className="per_page_text">Отображать</p>
          <div
            className="dropdown_button"
            onClick={() => setIsComponentVisible(true)}
          >
            <span>{perPage}</span>
          </div>
          <div ref={ref} className={`dropdown_wrapper ${isComponentVisible && 'visible'}`}>
            <div className="dropdown_item" onClick={handlePerPage(100)}><span>100</span></div>
            <div className="dropdown_item" onClick={handlePerPage(250)}><span>250</span></div>
            <div className="dropdown_item" onClick={handlePerPage(500)}><span>500</span></div>
          </div>
        </div>
        )}
      </div>

      <div className={`table_filters_block ${isSecondaryStyle ? 'table_filters_block_second' : ''}`}>
        {Boolean(filters?.length) && (
        <div className="table_filters_row">
          {filters.map((filter) => (
            <div
              key={filter.id}
              onClick={() => setSelectedFilter(filter)}
              className={`filter_item ${selectedFilter?.id === filter.id ? 'selected' : ''}`}
            >
              {filter.name}
            </div>
          ))}
        </div>
        )}

        {isSecondaryStyle && (
        <div className="per_page">
          <p className="per_page_text">Отображать</p>
          <div
            className="dropdown_button"
            onClick={() => setIsComponentVisible(true)}
          >
            <span>{perPage}</span>
          </div>
          <div ref={ref} className={`dropdown_wrapper ${isComponentVisible && 'visible'}`}>
            <div className="dropdown_item" onClick={handlePerPage(100)}><span>100</span></div>
            <div className="dropdown_item" onClick={handlePerPage(250)}><span>250</span></div>
            <div className="dropdown_item" onClick={handlePerPage(500)}><span>500</span></div>
          </div>
        </div>
        )}

        <SearchInput value={q} onChange={setQ} placeholder="Поиск" />
      </div>

      <TableHeader columns={headerColumns} />
      <div className="table_scroll">
        {data.length > 0 ? (
          data.map(Item)
        ) : (
          <p className="table_scroll_text">Нет результатов по данному запросу</p>
        )}
      </div>

      <TablePagination
        page={page}
        setPage={setPage}
        pagesCount={perPage && isFinite(perPage) ? Math.ceil(total / perPage) : 1}
      />
    </div>
  );
}

export default MyTable;
