import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PageTitle from '../../components/PageTitle';
import MyTable from '../../components/MyTable';
import useInputState from '../../helpers/hooks/useInputState';
import UserItem from './components/UserItem';
import UserModal from './components/UserModal';
import { USER_FILTERS, USER_HEADER_COLUMNS, USER_WIDTHS } from './constants';
import { getUsersRequest } from '../../store/actions/users';

function Users() {
  const dispatch = useDispatch();
  const {
    users, usersTotal, usersLoading, usersError,
  } = useSelector((store) => store.users);
  const [page, setPage] = useInputState(1);
  const [perPage, setPerPage] = useState(100);
  const [selectedFilter, setSelectedFilter] = useState(USER_FILTERS[0]);
  const [q, setQ] = useInputState();
  const [isInit, setIsInit] = useState(false);

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [modalState, setModalState] = useState('');
  const [selectedUser, setSelectedUser] = useState({});
  const [itemsPassed, setItemsPassed] = useState(0);

  const getUsers = ({ paramsPage, paramsPerPage = perPage, isInitRequired = true }) => {
    if (usersLoading || (isInitRequired && !isInit)) return;

    dispatch(getUsersRequest({
      q,
      page: paramsPage,
      per_page: paramsPerPage,
      is_active: selectedFilter.id,
      should_add_views: 'false',
    }));
  };

  const TableItemComponent = useCallback((item, index) => (
    <UserItem
      key={item._id}
      item={item}
      itemsPassed={itemsPassed}
      widths={USER_WIDTHS}
      index={index}
      setSelectedUser={setSelectedUser}
      setModalState={setModalState}
      setIsModalVisible={setIsModalVisible}
    />
  ), [itemsPassed]);

  const handlePage = (newVal) => {
    if (newVal === page) return;
    setPage(newVal);
    getUsers({ paramsPage: newVal });
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      setPage(1);

      if (perPage < 1) {
        setPerPage(1);
        getUsers({ paramsPage: 1, paramsPerPage: 1 });
      } else if (perPage > 500) {
        setPerPage(500);
        getUsers({ paramsPage: 1, paramsPerPage: 500 });
      } else {
        getUsers({ paramsPage: 1 });
      }
    }, 600);

    return () => clearTimeout(timer);
  }, [perPage]);

  useEffect(() => {
    const timer = setTimeout(() => {
      setPage(1);
      getUsers({ paramsPage: 1 });
    }, 400);

    return () => clearTimeout(timer);
  }, [q]);

  useEffect(() => {
    setPage(1);
    getUsers({ paramsPage: 1 });
  }, [selectedFilter]);

  useEffect(() => {
    getUsers({ paramsPage: page, isInitRequired: false });
    setTimeout(() => {
      setIsInit(true);
    }, 500);
  }, []);

  useEffect(() => {
    setItemsPassed((page - 1) * perPage);
  }, [users]);

  return (
    <div>
      <PageTitle text="Пользователи" />

      <MyTable
        q={q}
        setQ={setQ}
        filters={USER_FILTERS}
        perPage={perPage}
        setPerPage={setPerPage}
        selectedFilter={selectedFilter}
        setSelectedFilter={setSelectedFilter}
        headerColumns={USER_HEADER_COLUMNS}
        Item={TableItemComponent}
        data={users}
        page={page}
        setPage={handlePage}
        total={usersTotal}
      />

      <UserModal
        selectedUser={selectedUser}
        modalState={modalState}
        isVisible={isModalVisible}
        setIsVisible={setIsModalVisible}
        setSelectedUser={setSelectedUser}
      />
    </div>
  );
}

export default Users;
